<template>
  <a-card :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }" :headStyle="{ paddingRight: 0, }">
    <template #title>
			<h6 class="font-semibold m-0">修改密码</h6>
		</template>
    <a-spin :spinning="spinning">
      <a-row>
      <a-input v-model="password" placeholder="请输入密码"></a-input>
    </a-row>
    <a-row>
      <a-input v-model="repeatPWD" placeholder="请再次输入密码"></a-input>
    </a-row>
    <a-row style="text-align: center;">
      <a-button type="danger" @click="handle">修改</a-button>
    </a-row>
    </a-spin>
  </a-card>
</template>


<script>
export default ({
  data() {
    return {
      spinning: false,
      password: '',
      repeatPWD: '',
    }
  },
  methods: {
    handle() {
      if (!this.password || this.password != this.repeatPWD) {
        this.$message.error('请检查输入密码是否一致')
        return
      }
      if (this.password.length < 8) {
        this.$message.error('密码需要超过8位')
        return
      }
      this.spinning = true
      this.$axios.post('/api/platform/user/modifyPassword', { password: this.password })
        .then(res => {
          this.spinning = false
          this.$message.info(res.msg)
          this.password = this.repeatPWD = ''
      })
    }
  },
})
</script>

<style scoped>

.ant-row {
  margin: 10px;
}

</style>

