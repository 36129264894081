<template>
	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information"
		:bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }" :headStyle="{ paddingRight: 0, }">
		<template #title>
			<h6 class="font-semibold m-0">个人信息</h6>
		</template>
		<a-button type="link" slot="extra">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted"
					d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
					fill="#111827" />
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
					fill="#111827" />
			</svg>
		</a-button>
		<p hidden class="text-dark">
			Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the
			one more painful in the short term (pain avoidance is creating an illusion of equality).
		</p>
		<hr hidden class="my-25">
		<a-descriptions :column="1">
			<a-descriptions-item label="昵称">
				{{ userInfo.nick }}
			</a-descriptions-item>
			<a-descriptions-item label="用户名">
				{{ userInfo.username }}
			</a-descriptions-item>
			<a-descriptions-item label="手机">
				{{ userInfo.phone }}
			</a-descriptions-item>
			<a-descriptions-item label="邮箱">
				{{ userInfo.email }}
			</a-descriptions-item>
			<a-descriptions-item label="身份码">
				<a-space style="margin-right: 10px;">{{ userInfo.identifyCode }}</a-space>
				<a-button @click="copy(userInfo.identifyCode)" icon="copy" size="small" />
			</a-descriptions-item>
			<a-descriptions-item label="会员">
				{{ premium }}
			</a-descriptions-item>
		</a-descriptions>
	</a-card>
	<!-- / Profile Information Card -->
</template>

<script>
import { Icon } from 'ant-design-vue'

const IconFont = Icon.createFromIconfontCN({
	scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
})

export default ({
	components: {
		IconFont,
	},
	data() {
		return {
			userInfo: this.$store.state.userInfo
		}
	},
	computed: {
		premium() {
			return this.userInfo.premium ? '是' : '否'
		}
	},
	methods: {
		async copy(content) {
      await navigator.clipboard.writeText(content)
			this.$message.info('复制成功')
		}
	}
})

</script>